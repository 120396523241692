import useComponentVisible from "lib/click-outside"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useStore } from "components/ui"
import { RESOURCE_KEY } from "lib/Const"

const NavItem = ({ icon, title, url, state, target, lengthItems }) => {
  return (
    <Link
      target={target}
      to={url}
      state={state}
      className={`flex flex-col items-center ${lengthItems >= 3 ? "w-1/3" : `w-1/${lengthItems}`}  p-3`}
    >
      <div className="max-w-9 max-h-9" dangerouslySetInnerHTML={{ __html: icon }}></div>
      <p className=" text-[10px] text-ellipsis truncate">{title}</p>
    </Link>
  )
}

export default function MainNavigation() {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const { t } = useTranslation()
  const tabId = sessionStorage.tabID
  const { permission } = useStore()
  const items = [
    // root_service
    permission[RESOURCE_KEY.ROOT_SERVICE]?.GET && {
      id: 1,
      icon: icAdminPortal,
      //title: "Admin Portal",
      title: "177_33703",
      url: process.env.REACT_APP_ADMIN_CMS_URL,
      state: tabId,
      target: ""
    },
    // root_dashboard_idm
    permission[RESOURCE_KEY.ROOT_DASHBOARD]?.GET && {
      id: 2,
      icon: icIDM,
      //title: "Identity Management",
      title: "177_33713",
      url: `${process.env.REACT_APP_ID_CMS_URL}/dashboards`,
      state: tabId,
      target: ""
    },
    // root_project_resource
    permission[RESOURCE_KEY.ROOT_PROJECT_RESOURCE]?.GET && {
      id: 3,
      icon: icDevelopConsole,
      //title: "Develop Console",
      title: "177_33709",
      url: process.env.REACT_APP_CONTROL_PLANE_CMS_URL,
      state: tabId,
      target: ""
    },
    // root_ac_report
    permission[RESOURCE_KEY.ROOT_REPORT]?.GET && {
      id: 4,
      icon: icDownLoad,
      //title: "Export Management",
      title: "1_10492",
      url: "/exports",
      state: tabId,
      target: ""
    },
    // root_ac_report
    permission[RESOURCE_KEY.ROOT_REPORT]?.GET && {
      id: 5,
      icon: icClientPortal,
      //title: "Client Portal",
      title: "1_13597",
      url: "/top",
      state: tabId,
      target: ""
    }
  ].filter(Boolean)
  const lengthItems = items.length
  return (
    <div className="cursor-pointer">
      <div
        onClick={() => setIsComponentVisible(true)}
        dangerouslySetInnerHTML={{ __html: icMainNav }}
      ></div>
      <div ref={ref}>
        {isComponentVisible && (
          <div
            className={`${lengthItems > 1 ? "right-10" : "-ml-16"} fixed top-16 z-20 bg-white px-4 py-4 rounded flex flex-wrap -mt-3 `}
            style={{
              boxShadow: `0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)`
            }}
          >
            {items.map(({ id, icon, title, url, state, target }) => (
              <NavItem
                key={id}
                icon={icon}
                title={t(title)}
                url={url}
                state={state}
                target={target}
                lengthItems={lengthItems}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const icAdminPortal = `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="currentColor" d="M12 23C6.443 21.765 2 16.522 2 11V5l10-4l10 4v6c0 5.524-4.443 10.765-10 12M4 6v5a10.58 10.58 0 0 0 8 10a10.58 10.58 0 0 0 8-10V6l-8-3Z"/><circle cx="12" cy="8.5" r="2.5" fill="currentColor"/><path fill="currentColor" d="M7 15a5.782 5.782 0 0 0 5 3a5.782 5.782 0 0 0 5-3c-.025-1.896-3.342-3-5-3c-1.667 0-4.975 1.104-5 3"/></svg>`

const icDevelopConsole = `<svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.16625 13.2243L3.94189 9.99996L7.16625 6.77561L7.756 7.37496L5.131 9.99996L7.756 12.625L7.16625 13.2243ZM4.99958 14.1666H5.83292V15.4166H14.1662V14.1666H14.9996V16.9871C14.9996 17.3707 14.8711 17.6909 14.6142 17.9479C14.3572 18.2048 14.037 18.3333 13.6534 18.3333H6.34573C5.96219 18.3333 5.64194 18.2048 5.385 17.9479C5.12805 17.6909 4.99958 17.3707 4.99958 16.9871V14.1666ZM5.83292 5.83329H4.99958V3.01277C4.99958 2.62923 5.12805 2.30899 5.385 2.05204C5.64194 1.7951 5.96219 1.66663 6.34573 1.66663H13.6534C14.037 1.66663 14.3572 1.7951 14.6142 2.05204C14.8711 2.30899 14.9996 2.62923 14.9996 3.01277V5.83329H14.1662V4.58329H5.83292V5.83329ZM5.83292 16.25V16.9871C5.83292 17.1153 5.88633 17.2329 5.99317 17.3397C6.10001 17.4465 6.21753 17.5 6.34573 17.5H13.6534C13.7816 17.5 13.8992 17.4465 14.006 17.3397C14.1128 17.2329 14.1662 17.1153 14.1662 16.9871V16.25H5.83292ZM5.83292 3.74996H14.1662V3.01277C14.1662 2.88458 14.1128 2.76706 14.006 2.66021C13.8992 2.55338 13.7816 2.49996 13.6534 2.49996H6.34573C6.21753 2.49996 6.10001 2.55338 5.99317 2.66021C5.88633 2.76706 5.83292 2.88458 5.83292 3.01277V3.74996ZM12.8329 13.2243L12.2432 12.625L14.8682 9.99996L12.2432 7.37496L12.8329 6.77561L16.0573 9.99996L12.8329 13.2243Z" fill="currentColor"/>
</svg>`

const icIDM = `<svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.99984 17.4679C8.32568 16.9712 6.93493 15.957 5.82757 14.4255C4.72019 12.8939 4.1665 11.1688 4.1665 9.24998V4.74357L9.99984 2.56409L15.8332 4.74357V9.24998C15.8332 11.1688 15.2795 12.8939 14.1721 14.4255C13.0647 15.957 11.674 16.9712 9.99984 17.4679ZM9.99984 16.5833C11.3471 16.1667 12.4721 15.3437 13.3748 14.1146C14.2776 12.8854 14.8054 11.5139 14.9582 9.99998H9.99984V3.4535L4.99984 5.31248V9.60896C4.99984 9.71686 5.01373 9.84721 5.0415 9.99998H9.99984V16.5833Z" fill="currentColor"/>\n</svg>`

const icMainNav = `<svg viewBox="64 64 896 896" focusable="false" data-icon="appstore" width="24" height="24" fill="currentColor" aria-hidden="true">
<path d="M464 144H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H212V212h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H612V212h200v200zM464 544H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H212V612h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H612V612h200v200z"></path>
</svg>`

const icDownLoad = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
</svg>
`

const icClientPortal = `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 20 20"><path fill="currentColor" d="M10 9.25c-2.27 0-2.73-3.44-2.73-3.44C7 4.02 7.82 2 9.97 2c2.16 0 2.98 2.02 2.71 3.81c0 0-.41 3.44-2.68 3.44m0 2.57L12.72 10c2.39 0 4.52 2.33 4.52 4.53v2.49s-3.65 1.13-7.24 1.13c-3.65 0-7.24-1.13-7.24-1.13v-2.49c0-2.25 1.94-4.48 4.47-4.48z"/></svg>`
