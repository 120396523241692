import { SIM_STATUS_NAME } from "./enum"

export function getSimStatus(status) {
  return SIM_STATUS_NAME[status] || "-"
}

export function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}
